<template>
  <v-container fluid>
    <v-row>
      <v-col md="3" cols="12">
        <v-select
          :value="e.status"
          :items="stat"
          label="aktueller Wettkampfstatus"
          disabled
        />
      </v-col>
      <v-col md="3" cols="12">
        <v-btn
          block
          :disabled="!(e.status === 'EventScheduled' || e.status === 'EventPostponed' || e.status === 'EventRescheduled')"
          @click="moveonline"
        >
          Wettkampf vorbereiten
        </v-btn>
      </v-col>
      <v-col md="3" cols="12">
        <v-btn
          block
          :disabled="!(e.status === 'EventScheduled' || e.status === 'EventPostponed' || e.status === 'EventRescheduled' || e.status === 'EventMovedOnline')"
          @click="start"
        >
          Wettkampf starten
        </v-btn>
      </v-col>
      <v-col md="3" cols="12">
        <v-btn
          block
          :disabled="!(e.status === 'EventScheduled' || e.status === 'EventPostponed' || e.status === 'EventRescheduled' || e.status === 'EventMovedOnline' || e.status === 'EventStarted')"
          @click="finish"
        >
          Wettkampf abschließen
        </v-btn>
      </v-col>
    </v-row>
    <h3>Erklärung des Status:</h3>
    <ul>
      <li><b>Event leer:</b> Der Wettkampf ist terminiert, es wurden noch keine Ergebnisse eingegeben.</li>
      <li><b>Event in Vorbereitung:</b> Turner machen sich gerade warm, es werden die Daten eingegeben. Der Wettkampf wird auf der Startseite angezeigt.</li>
      <li><b>Event begonnen:</b> Wettkampf läuft aktuell, der Wettkampf wird auf der Startseite angezeigt.</li>
      <li><b>Event beendet:</b> Wettkampf wurde beendet. Keine Änderungen mehr durch Vereinsverantwortliche mehr möglich.</li>
    </ul>
  </v-container>
</template>

<script>
import { useCalc } from '@/views/components/stb_w_2023/plugins/calc'
import { EventStatus } from '@/enum'
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'status',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc(props, context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    stat () {
      return EventStatus
    },
    allteams () {
      return this.e?.teams || []
    }
  },

  methods: {
    async moveonline () {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich in Vorbereitungsmodus setzen?', 'Der Wettkampf kann anschließend nicht mehr zurückgesetzt werden!')) {
        this.updatestatus('EventMovedOnline')
        this.$emit('moveonline')
      }
    },
    async start () {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich starten?', 'Die Ergebnisse sind dann öffentlich sichtbar!')) {
        this.updatestatus('EventStarted')
        this.$emit('start')
      }
    },
    async finish () {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich beenden?', 'Es können anschließend keine Änderungen mehr gemacht werden! Das Endergebnis wird hierbei automatisch übertragen und ist dann öffentlich sichtbar')) {
        await this.abschliessen()
        this.updatestatus('EventFinished')
        this.$emit('finish')
      }
    },
    updatestatus (status) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $status: EventStatus!) {
            StbLtf2024TcStatusUpdate(id: $id, status: $status) { _id }
          }`,
        variables: {
          id: this.id,
          status
        }
      })
    },
    async abschliessen () {
      /* for (const t of this.allteams) {
        const result = t.results.find(r => !r._discipline && r.type === 'stb_w_2023_wk_team')
        const team = t.team._id
        const final = this.mannschaftgesamtergebnis(this.e, this.r, t.team._id)
        const tp = this.mannschaftgesamtergebnistp(this.e, this.r, t.team._id)

        if (result) {
          await this.mutate({
            mutation: gql`
              mutation($id: UUID!, $final: Float!, $tp: Int!) {
                StbW2023WkTeamResultUpdate(id: $id, final: $final, tp: $tp) { _id }
              }
            `,
            variables: {
              id: result._id,
              final,
              tp
            }
          })
        } else {
          await this.mutate({
            mutation: gql`
              mutation($event: UUID!, $team: UUID!, $final: Float!, $tp: Int!) {
                StbW2023WkTeamResultAdd(event: $event, team: $team, final: $final, tp: $tp) { _id }
              }
            `,
            variables: {
              event: this.id,
              team,
              final,
              tp
            }
          })
        }
      } */
    }
  }
}
</script>

<style scoped>

</style>
